import { RGBELoader } from 'three-stdlib'
import { Canvas, useLoader, useFrame } from '@react-three/fiber'
import { useRef, useState, useEffect } from 'react'
import {
  Center,
  Text3D,
  Instance,
  Instances,
  Environment,
  Lightformer,
  OrbitControls,
  RandomizedLight,
  AccumulativeShadows,
  MeshTransmissionMaterial, useGLTF, useTexture
} from '@react-three/drei'
import { useControls } from 'leva'
import { EffectComposer, HueSaturation, BrightnessContrast } from '@react-three/postprocessing'

function D20({config, ...props}) {
  const csg = useRef()
  const { nodes } = useGLTF('/Dodecaedro_D20.glb')

  const mesh = useRef()
  useFrame((state, delta) => (mesh.current.rotation.y = mesh.current.rotation.x += delta))

  return (
    <group {...props} dispose={null}>
      <mesh ref={mesh} receiveShadow castShadow geometry={nodes.Mesh_Dodecaedro.geometry}>
      <MeshTransmissionMaterial
          {...config}/>
      </mesh>
    </group>
  )
}

function SceneA({text, config, ...props}) {

  const d20Scale = props.windowWidth < 1920 ? 2.1 : 3.8

  return (<>  
      <Text config={config} rotation={[0.1, 0, 0]} position={[0.25, -40, 4.25]} scale={0.0001}>
        {text}
      </Text>
      <D20 config={config} position={[0, 0, 0]} scale={d20Scale}/>
      <OrbitControls enableZoom={false} />
      <Environment resolution={32}>
        <group rotation={[-Math.PI / 4, -0.3, 0]}>
          <Lightformer intensity={20} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={[10, 10, 1]} />
          <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[10, 2, 1]} />
          <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-5, -1, -1]} scale={[10, 2, 1]} />
          <Lightformer intensity={2} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[20, 2, 1]} />
          <Lightformer type="ring" intensity={20} rotation-y={Math.PI / 2} position={[-0.1, -1, -5]} scale={10} />
        </group>
      </Environment>
      {/* <AccumulativeShadows frames={100} color={config.shadow} colorBlend={5} toneMapped={true} alphaTest={0.9} opacity={1} scale={30} position={[0, -1.01, 0]}>
        <RandomizedLight amount={4} radius={10} ambient={0.5} intensity={1} position={[0, 8, -10]} size={15} mapSize={1024} bias={0.0001} />
      </AccumulativeShadows> */}
  </>)
}

function Text({ children, config, font = '/Inter_Medium_Regular.json', ...props }) {
  const texture = useLoader(RGBELoader, 'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr')
  
  return (
    <>
      <group>
        <Center scale={[0.8, 1, 1]} front top {...props}>
          <Text3D
            bevelEnabled
            font={font}
            scale={5}
            letterSpacing={-0.03}
            height={0.25}
            bevelSize={0.01}
            bevelSegments={10}
            curveSegments={128}
            bevelThickness={0.01}>
            {children}
            <MeshTransmissionMaterial {...config} background={texture} emissive={"#ff7eb3"} emissiveIntensity={0.06} />
          </Text3D>
        </Center>
      </group>
    </>
  )
}

export function App() {
  const  config  =
  useControls(
  {
    width: window.innerWidth,
    backside: true,
    backsideThickness: { value: 0.3, min: 0, max: 2 },
    samples: { value: 16, min: 1, max: 32, step: 1 },
    resolution: { value: 1024, min: 64, max: 2048, step: 64 },
    transmission: { value: 0.75, min: 0, max: 1 },
    clearcoat: { value: 0, min: 0.1, max: 1 },
    clearcoatRoughness: { value: 0.0, min: 0, max: 1 },
    thickness: { value: 1, min: 0, max: 5 },
    chromaticAberration: { value: 0.45, min: 0, max: 5 },
    anisotropy: { value: 0.25, min: 0, max: 1, step: 0.01 },
    roughness: { value: 0, min: 0, max: 1, step: 0.01 },
    distortion: { value: 0.75, min: 0, max: 4, step: 0.01 },
    distortionScale: { value: 0.1, min: 0.01, max: 1, step: 0.01 },
    temporalDistortion: { value: 0, min: 0, max: 1, step: 0.01 },
    ior: { value: 1.5, min: 0, max: 2, step: 0.01 },
    color: '#cd9cff',
    gColor: '#ff7eb3',
    shadow: '#450d35',
    backgroundColor: '#121212'
  }
)

  const text = "loading...";

  const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight
  });

  useEffect(() => {
      const handleResize = () => {
          setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight
          });
      };

      window.addEventListener('resize', handleResize);

      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  return (
    <Canvas shadows orthographic camera={{ position: [0, 5, 10], zoom: 80 }} gl={{ preserveDrawingBuffer: true }}>
      <SceneA text={text} config={config} windowWidth={windowSize.width}/>
    </Canvas>
  )
}