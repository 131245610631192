import { createRoot } from 'react-dom/client'
import './styles.css'
import { App } from './App'
import { Leva } from 'leva'

function Overlay() {
  return (
    <div className="overlay">
      <div className='logoContainer'>
        <img src="Logo.png" />
      </div>
      <div className="socialContainer">
        <a href="https://x.com/d20_labs" target='_blank'>
          <img src="IconX.png"/>
        </a>
        <a href="https://hub.xyz/sugartown" target='_blank'>
          <img src="IconS.png" />
        </a>
      </div>
      <div className="sloganContainer">
        <div className="slogan">
          Creating the future of gaming through rewarded play.
        </div>
      </div>
    </div>
  )
}

createRoot(document.getElementById('root')).render(
  <>
    <App />
    <Leva collapsed hidden/>
    <Overlay />
  </>
)
